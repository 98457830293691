<template>
  <el-dialog
    custom-class="cus-column-dialog common-dialog"
    width="800px"
    title="编辑自定义列"
    :visible="visible"
    @close="handleClose"
    :append-to-body="true"
  >
    <div class="top dp-flex justify-btw">
      <el-input
        @input="handleSearch(searchIpt)"
        v-model="searchIpt"
        size="small"
        placeholder="请输入"
      >
        <template slot="append"><i class="el-icon-search"></i></template>
      </el-input>
      <span class="f-12"> 点住<i class="el-icon-rank"></i>不放，拖动列的位置 </span>
    </div>
    <div class="center dp-flex justify-btw pr">
      <el-tabs
        class="h100"
        tab-position="left"
      >
        <el-tab-pane
          v-for="(item, index) in columnGroups"
          :key="index"
          :label="item.columnGroup.title"
        >
          <check-all
            :allCheckboxDatas="item.columnFields"
            :checkedInitDatas="item.checkedDatas ? item.checkedDatas.map((item) => item.keyName) : []"
            @post-checked-datas="(val) => getCheckedDatas(val, index)"
          ></check-all>
        </el-tab-pane>
      </el-tabs>
      <div class="bg-f drag-area h100 overflow-auto">
        <p class="item">名称</p>
        <p class="item">状态</p>
        <!-- 
           :initList="allCheckedDatas"  
         :initList="wantArr"-->
        <cus-drag
          ref="dragRef"
          :initList="wantArr"
          @del-item="handleDelItem"
          @sort-change="sortChange"
        ></cus-drag>
      </div>
      <!-- 前端模拟搜索 -->
      <div
        class="pa search-show-area"
        v-if="canSearch"
      >
        <search-show-area
          :allCheckedDatas="searchCheckedDatas"
          :allCheckboxDatas="searchResultDatas"
          @change-search-checks="handleChangeSearchChecks"
        ></search-show-area>
      </div>
    </div>
    <el-form
      v-if="isSearchOpt"
      ref="formRef"
      :model="formModel"
      class="btm dp-flex justify-end align-item-c"
    >
      <el-form-item
        prop="name"
        class="mb-0"
        :rules="{
          validator: (rule, value, callback) => handleValidateName(rule, value, callback, formModel.isSearchOpt),
          trigger: ['change', 'blur'],
        }"
      >
        <el-input
          clearable
          v-if="formModel.isSearchOpt"
          size="small"
          v-model="formModel.name"
          placeholder="请输入名称"
        ></el-input>
      </el-form-item>
      <el-form-item
        prop="isSearchOpt"
        class="mb-0"
      >
        <el-checkbox
          :disabled="name ? true : false"
          v-model="formModel.isSearchOpt"
        >
          保存为查询条件
        </el-checkbox>
      </el-form-item>
    </el-form>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        size="small"
        @click="handleClose"
      >
        取 消
      </el-button>
      <el-button
        size="small"
        type="primary"
        @click="handleConfirm"
      >
        确 定
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import { allColumns, saveColumn } from '@/api/google/adManagement';
import checkAll from './checkAll';
import cusDrag from './cusDrag';
import searchShowArea from './searchShowArea';
export default {
  components: {
    checkAll,
    cusDrag,
    searchShowArea,
  },
  props: {
    visible: {
      type: Boolean,
      default: () => false,
    },
    initCheckedColumns: {
      type: Array,
      default: () => [],
    },
    name: {
      type: String,
      default: () => '',
    },
    isSearchOpt: {
      type: Boolean,
      default: () => true,
    },
    // 图表抽屉组件中的自定义列中左侧过滤掉设置分组和indicatorType为"T0"的字段
    hasFilterFields: {
      type: Boolean,
      default: () => false,
    },
    accountId: {
      type: [String, Array],
      default: '',
    },
  },
  data() {
    return {
      searchIpt: '',
      searchCheckedDatas: [],
      searchResultDatas: [],
      canSearch: false,
      // 左侧列所属的组-(所有列)
      columnGroups: [],
      // 用户选中的列数据
      allCheckedDatas: [],
      // 自定义列组合的名字
      formModel: {
        name: '', // 自定义列组合是否保存为查询条件
        isSearchOpt: false,
      },
      // 必需列arr
      requiredColumns: ['id', 'name', 'status', 'budget'],
      // 根据传入的初始列数组得到的右侧拖放区域的数组-顺序与传入的数组顺序一致
      wantArr: [],
    };
  },
  computed: {
    allColumnsArr() {
      let arr = [];
      this.columnGroups.forEach((item) => {
        arr.push(...item.columnFields);
      });
      return arr;
    },
  },
  mounted() {},
  watch: {
    columnGroups: {
      handler(val) {
        // console.log("columnGroups", val);
        // 得到用户所有选中的列数据
        let arr = [];
        val.forEach((item) => {
          if (item.checkedDatas) {
            arr.push(...item.checkedDatas);
          }
        });
        this.allCheckedDatas = [...arr];
        // console.log("this.allCheckedDatas", this.allCheckedDatas);
        // allCheckedDatas和wantArr对比，多的从wantArr中去除，少的向wantArr中添加
        this.wantArr = this.addOrMinusFn(this.allCheckedDatas, this.wantArr);
      },
      deep: true,
    },
    isSearchOpt(val) {
      if (!val) {
        this.$refs.formRef.clearValidate();
      }
    },
    visible(val) {
      if (val) {
        // 左侧列所属的组-(所有列)
        this.getColumnGroups();
        this.formModel.name = this.name;
        this.formModel.isSearchOpt = this.name ? true : false;
      }
    },
    initCheckedColumns: {
      handler(val) {
        // console.log("2-w-initCheckedColumns",val)
      },
    },
  },
  methods: {
    sortChange(arr) {
      // 右侧拖拽区排序改变，初始数据wantArr随之改变
      this.wantArr = [...arr];
    },
    // arr1和arr2对比，多的从arr2中去除，少的向arr2中添加
    addOrMinusFn(arr1, arr2) {
      console.log(arr1, arr2);
      let arr = [];
      let adds = arr1.filter((item1) => arr2.every((item2) => item2.keyName != item1.keyName));
      let commons = arr2.filter((item2) => arr1.some((item1) => item1.keyName == item2.keyName));
      arr = commons.concat(adds);
      // console.log("commons,adds,arr", commons, adds, arr);
      return arr;
    },
    // 格式化初始选中列
    formatInitCheckCulumns() {
      let val = this.initCheckedColumns;
      if (val && val.length) {
        let arr = val.filter((item) => !this.requiredColumns.includes(item));
        let wantArr = [];
        let fbs = this.columnGroups.map((item) => item.columnFields);
        // 扁平化数组
        let flatFbs = fbs.flat();
        arr.forEach((item) => {
          let obj = flatFbs.find((f) => f.keyName == item);
          if (obj) {
            wantArr.push(obj);
          }
        });
        // console.log("wantArr", wantArr);
        this.wantArr = wantArr;
      }
    },
    // 搜索结果选中状态改变=>改变allCheckedDatas=>改变右侧选中列
    handleChangeSearchChecks(val) {
      this.searchResultDatas.forEach((item) => {
        // 该项是否在右侧选中列中
        let hasItem = this.allCheckedDatas.some((c) => c.keyName == item.keyName);
        // 在右侧选中列中
        if (hasItem) {
          // 是否选中
          let obj = val.find((v) => v == item.keyName);
          if (!obj) {
            let group = this.columnGroups.find((g) => g.columnGroup.id == item.columnGroup.id);
            let index = group.checkedDatas.findIndex((f) => f.keyName == item.keyName);
            group.checkedDatas.splice(index, 1);
          }
          // 不在右侧选中列中
        } else {
          // 是否选中
          let obj = val.find((v) => v == item.keyName);
          if (obj) {
            let group = this.columnGroups.find((g) => g.columnGroup.id == item.columnGroup.id);
            let _item = group.columnFields.find((f) => f.keyName == item.keyName);
            group.checkedDatas.push(_item);
          }
        }
      });
    },
    handleSearch(searchIpt) {
      if (searchIpt) {
        let arr = this.allColumnsArr.filter((item) => item.name.indexOf(searchIpt) != -1);
        if (arr) {
          this.searchResultDatas = [...arr];
        } else {
          this.searchResultDatas = [];
        }
        // 得到searchCheckedDatas
        let arr2 = this.allCheckedDatas.filter((item) => this.searchResultDatas.some((i) => i.keyName == item.keyName));
        this.searchCheckedDatas = arr2 ? [...arr2] : [];
        this.canSearch = true;
      } else {
        this.canSearch = false;
      }
    },
    handleClose() {
      this.$emit('close-dialog');
      if (this.isSearchOpt) {
        this.$refs.formRef.resetFields();
      }
      this.searchIpt = '';
      this.canSearch = false;
    },
    saveColumn(arr, name) {
      this.$showLoading();
      let formData = new FormData();
      formData.append(
        'columns',
        arr.map((item) => item.keyName),
      );
      formData.append('name', name);
      console.log(arr, formData, '提交的数据');
      saveColumn(formData).then((res) => {
        this.$hideLoading();
        if ((res.comment = 'ok')) {
          // 重新渲染定制列下拉框数据
          // 重新渲染表格
          // this.$emit("post-save-res", res);
          this.$emit('post-save-res', arr);
        }
      });
    },
    // 自定义列-确定
    handleConfirm() {
      let arr = this.$refs.dragRef.myArray;
      if (this.isSearchOpt) {
        this.$refs.formRef.validate((valid) => {
          if (valid) {
            if (this.formModel.name) {
              this.saveColumn(arr, this.formModel.name);
            } else {
              // 重新渲染表格
              this.$emit('post-cus-columns', arr);
            }
            this.$emit('close-dialog');
          } else {
            return false;
          }
        });
      } else {
        // 重新渲染表格
        this.$emit('post-cus-columns', arr);
        this.$emit('close-dialog');
      }
    },
    allColumns() {
      this.$showLoading();
      allColumns({
        accountId: this.accountId.toString(),
      }).then((res) => {
        this.$hideLoading();
        if (res.comment == 'ok') {
          // console.log("allColumns=>res.data", res.data);
          // 图表抽屉组件中的自定义列中左侧过滤掉设置分组和indicatorType为"T0"的字段
          let resData = JSON.parse(JSON.stringify(res.data));
          let _resData = [];
          if (this.hasFilterFields) {
            _resData = resData.filter(
              (item) => item.columnGroup.id != 'SETTING' && item.columnFields.every((f) => f.indicatorType != 'T0'),
            );
          } else {
            _resData = resData;
          }
          // console.log("_resData", _resData);
          _resData.forEach((item) => {
            let arr = item.columnFields.filter((f) => this.initCheckedColumns.some((c) => c == f.keyName));
            // 默认选中列
            this.$set(item, 'checkedDatas', arr ? arr : []);
          });
          this.columnGroups = [..._resData];
          // console.log("columnGroups", this.columnGroups);
          this.formatInitCheckCulumns();
        }
      });
    },
    // 左侧列所属的组-(所有列)
    getColumnGroups() {
      if (!this.accountId || !this.accountId.length) return;
      this.allColumns();
    },
    // 根据选中的keyName,依据所有列（columnGroups）-format-newArr
    formatCheckedKeys(val, index) {
      let arr = [];
      let lists = this.columnGroups[index].columnFields;
      val.forEach((v) => {
        let obj = lists.find((item) => item.keyName == v);
        arr.push(obj);
      });
      return arr;
    },
    // 用户选择时得到-当前tab-pane-的选中数据
    getCheckedDatas(val, index) {
      // 根据选中的keyName,依据所有列（columnGroups）-得到包含name、width等属性的对应对象组成的新数组
      this.$set(this.columnGroups[index], 'checkedDatas', this.formatCheckedKeys(val, index));
    },
    handleDelItem(item) {
      // console.log("右侧删除-handleDelItem", item);
      let obj = this.columnGroups.find((c) => c.columnGroup.id == item.columnGroup.id);
      let index = obj.checkedDatas.findIndex((i) => i.keyName == item.keyName);
      obj.checkedDatas.splice(index, 1);
    },
    handleValidateName(rule, value, callback, isSearchOpt) {
      if (isSearchOpt && !value) {
        callback(new Error('请输入名称！'));
      } else {
        callback();
      }
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/commonly';
.cus-column-dialog {
  .el-dialog__body {
    @extend .bg-theme;
  }
  .top {
    .el-input {
      width: 200px;
      margin-left: 100px;
      margin-bottom: 10px;
    }
    & > span {
      margin-right: 80px;
      margin-top: 10px;
    }
    i.el-icon-rank {
      vertical-align: middle;
    }
  }
  .center {
    height: 450px;
    .bd-set {
      border: 1px solid rgb(218, 220, 221);
      border-radius: 4px;
    }
    .el-tabs__header {
      margin-right: 24px;
    }
    .el-tabs__item {
      height: auto;
      line-height: initial;
      color: $theme-color;
      padding: 8px 24px 24px;
      @extend .text-a-l;
      &:hover {
        color: $theme;
      }
      &.is-active {
        color: $theme;
      }
    }
    .el-tabs__active-bar {
      height: 51px !important;
    }
    .el-tabs__content {
      width: 300px;
      padding-left: 20px;
      @extend .bg-f;
      @extend .bd-set;
      @extend .h100;
      .el-tab-pane {
        @extend .h100;
      }
    }
    // 拖拽
    .drag-area {
      width: 300px;
      @extend .bd-set;
    }
  }
  .drag-area {
    .item {
      padding: 6px 10px;
      border-bottom: 1px solid rgb(238, 238, 238);
    }
  }
  i.el-icon-rank {
    font-size: 18px;
    color: rgb(204, 204, 204);
  }
  .btm {
    padding-top: 15px;
    .el-form-item {
      width: 186px;
    }
    .el-checkbox {
      margin-left: 16px;
      height: 41px;
      line-height: 41px;
    }
    .el-checkbox__label {
      color: $theme-color;
      padding: 0 8px;
    }
  }
  .search-show-area {
    @extend .bg-f;
    left: 100px;
    width: 302px !important;
    z-index: 100;
    padding: 10px;
    border: 1px solid #dadcdd;
    border-radius: 0 4px 4px 0;
    height: 520px !important;
    overflow: auto;
  }
}
</style>
